document.write(`
    <!--底部开始-->
    <div class="footer">
        <div class="container ">
            <div class="footer-top">
                <div class="footer-logo">
                    <a href=""><img src="skin/images/bottom-logo.png"></a>
                </div>
                <div class="footer-link">
                    <a href="" target="_blank" class="wechat"><img src="skin/images/004.png" hover-src="skin/images/004_.png" out-src="skin/images/004.png"></a>
                    <div class="ewmbox"><img src="skin/images/code.png" width="70"></div>
                    <a href="" target="_blank"><img src="skin/images/003.png" hover-src="skin/images/003_.png" out-src="skin/images/003.png"></a>
                    <a href="" target="_blank"><img src="skin/images/002.png" hover-src="skin/images/002_.png" out-src="skin/images/002.png"></a>
                    <a href="" target="_blank"><img src="skin/images/001.png" hover-src="skin/images/001_.png" out-src="skin/images/001.png"></a>
                </div>
            </div>
            <div class="footer-code">
                <!-- <p>ETS is professionally managed by Association Headquarters, a charter accredited association management company.</p> -->
                <p>Copyright © 2021 ISETS. All Rights Reserved</p>
            </div>
        </div>
    </div>
`)

require(['tool'],function (){
    $(".footer-link a img").hover(function(){
        var cTihs = $(this)
        var hoverSrc = cTihs.attr("hover-src")
        cTihs.attr("src",hoverSrc)
    },function(){
        var cTihs = $(this)
        var outSrc = cTihs.attr("out-src")
        cTihs.attr("src",outSrc)
    })
});